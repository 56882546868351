
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { formatDate } from "@/core/helpers/genericHelper";
import { formatType } from "@/core/helpers/genericHelper";
import {VueCookieNext} from "vue-cookie-next";

export default defineComponent({
  name: "EventList",
  setup() {
    const eventList = ref([]);
    const componentOptions = ref([]);
    const divisionOptions = ref([]);
    const districtOptions = ref([]);
    const exportType = ref(null);
    const iaPartnerOptions = ref([]);
    const selectedPartner = ref('');

    const getComponent = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_COMPONENT_LIST
        );

        componentOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivision = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_DIVISION_LIST
        );

        divisionOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.division_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivisionWistDistrict = async () => {
      try {
        const geo_division_id = (
          document.getElementById("geo_division_id") as HTMLInputElement
        )?.value;

        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DISTRICT_LIST,
          {
            geo_division_id: geo_division_id,
          }
        );
        console.log(response);
        districtOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.district_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const loadData = async () => {
      try {
        const component_id = (
          document.getElementById("component_id") as HTMLInputElement
        )?.value;
        const geo_division_id = (
          document.getElementById("geo_division_id") as HTMLInputElement
        )?.value;
        const geo_district_id = (
          document.getElementById("geo_district_id") as HTMLInputElement
        )?.value;
        const event_institute = (
          document.getElementById("event_institute") as HTMLInputElement
        )?.value;

        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_EVENT_REPORT,
          {
            ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") != 'null'
                ? VueCookieNext.getCookie("_ia_partner_id")
                : selectedPartner.value,
            component_id: component_id,
            geo_division_id: geo_division_id,
            geo_district_id: geo_district_id,
            event_institute: event_institute,
          }
        );
        eventList.value = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getIaPartnerOptions = async () => {
      try {

        const id = VueCookieNext.getCookie("_ia_partner_id") !== "null"
            ? VueCookieNext.getCookie("_ia_partner_id")
            : 0;

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_IAPARTNER_LIST,
            {
              id : id
            }
        );

        iaPartnerOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));

      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const exportChange = async () => {
      if (exportType.value == "excel") {
        exportExcel();
      }
    };

    const exportExcel = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_EVENT_REPORT_EXPORT,
          {}
        );

        if (response.data.status === "success" && response.data.url) {
          // Create a link element
          const downloadLink = document.createElement("a");
          downloadLink.href = apiEndpoint.data().BEE_FILE_URL+response.data.url;
          console.log(downloadLink.href);
          downloadLink.target = "_blank"; // Open in a new tab/window
          downloadLink.download = "event_report.xlsx"; // Specify the desired file name

          // Append the link to the document
          document.body.appendChild(downloadLink);

          // Trigger a click event on the link to start the download
          downloadLink.click();

          // Remove the link from the document
          document.body.removeChild(downloadLink);
        } else {
          console.error("Invalid response for export:", response);
        }
      } catch (error) {
        console.error("Error exporting Excel:", error);
      }
    };

    onMounted(async () => {
      loadData();
      getComponent();
      getDivision();
      getIaPartnerOptions();
    });

    return {
      eventList,
      componentOptions,
      divisionOptions,
      districtOptions,
      getDivisionWistDistrict,
      loadData,
      formatDate,
      formatType,
      exportChange,
      exportType,
      getIaPartnerOptions,
      iaPartnerOptions,
      selectedPartner
    };
  },
});
